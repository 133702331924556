// account
export const SIGNUP_REGISTER_URL = '/auth/signup/register-info'
export const SIGNUP_CHECK_EMAIL_URL = '/auth/signup/checkEmail'
export const SIGNUP_CHECK_PASSWORD_URL = '/auth/login/checkPassword'
export const SIGNUP_VERIFY_TOKEN_URL = '/auth/login/verifyLogin'

export const LOGIN_CHECK_EMAIL_URL = '/auth/login/checkEmail'
export const LOGIN_CHECK_PASSWORD_URL = '/auth/login/checkPassword'
export const VERIFY_TOKEN_URL = '/auth/login/verifyLogin'

