import baseAPI from '@/api/base';
import { LoginCredentals, RegistrationCredentals, VerificationCredentials } from '../dtos/auth.dto';
import { LOGIN_CHECK_EMAIL_URL, LOGIN_CHECK_PASSWORD_URL, SIGNUP_REGISTER_URL, VERIFY_TOKEN_URL, SIGNUP_CHECK_EMAIL_URL } from '@/utils/urls';
import asyncHandler from '@/utils/async_handler';
import { AxiosResponse } from 'axios';

class Auth {
    static checkEmail = async(email: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(LOGIN_CHECK_EMAIL_URL, {
                    email
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                // console.log('error', error)
                reject(error)
            }
        })
    }

    static checkPassword = async(loginCredentals: LoginCredentals) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(LOGIN_CHECK_PASSWORD_URL, {
                    ...loginCredentals
                })

                resolve(response)
            } catch (error) {
                // console.log('error', error)
                reject(error)
            }
        })
    }

    static verifyToken = async(verificationCredentials: VerificationCredentials) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(VERIFY_TOKEN_URL, {
                    ...verificationCredentials
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                // console.log('error', error)
                reject(error)
            }
        })
    }

    static registrationCheckEmail = async(email: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(SIGNUP_CHECK_EMAIL_URL, {
                    email
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                // console.log('error', error)
                reject(error)
            }
        })
    }

    static signup = async(registrationCredentals: RegistrationCredentals) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(SIGNUP_REGISTER_URL, {
                    ...registrationCredentals
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                // console.log('error', error)
                reject(error)
            }
        })
    }

    // static verifyEmail = async(authToken: String) : Promise<AxiosResponse> => {
    //     return new Promise(async(resolve, reject) => {
    //         try {
    //             let response: AxiosResponse = await baseAPI.put(`${EMAIL_VERIFICATION_URL}/${authToken}`)

    //             resolve(response)
    //         } catch (error) {
    //             reject(error)
    //         }
    //     })
    // }
}

export default Auth