import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { ifAuthDeny } from './guards/auth'
import HomeView from '../views/Home.vue'
import { HOME_ROUTE, LOGIN_CHECK_EMAIL_ROUTE, LOGIN_CHECK_PASSWORD_ROUTE, LOGIN_VERIFY_ROUTE, SIGNUP_CHECK_EMAIL_ROUTE, SIGNUP_ROUTE, SIGNUP_VERIFY_ROUTE } from './routes'
import { ifNotAuthDeny } from './guards/not_auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: HOME_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: HomeView
  },
  {
    path: '/auth/login/check-email',
    name: LOGIN_CHECK_EMAIL_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('../views/auth/login/Check_Email.vue')
  },
  {
    path: '/auth/login/check-password',
    name: LOGIN_CHECK_PASSWORD_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('../views/auth/login/Check_Password.vue')
  },
  {
    path: '/auth/login/verify',
    name: LOGIN_VERIFY_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('../views/auth/login/Verify_Token.vue')
  },
  {
    path: '/auth/signup/check-email',
    name: SIGNUP_CHECK_EMAIL_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('../views/auth/signup/Check_Email.vue')
  },
  {
    path: '/auth/signup/verify',
    name: SIGNUP_VERIFY_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('../views/auth/signup/Verify_Token.vue')
  },
  {
    path: '/auth/signup/additional-information',
    name: SIGNUP_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('../views/auth/signup/Register.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
