import { createStore } from 'vuex'
import createVuexPersistedState from 'vuex-persistedstate';
import { LOCAL_STORAGE_KEY } from "@/utils/const";
import auth from "./modules/auth";
import error from './modules/error';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    error
  },
  plugins: [
    createVuexPersistedState({
        key: LOCAL_STORAGE_KEY,
        storage: window.localStorage
    })
  ]
})
