import axios, { AxiosResponse } from 'axios'
import router from '../../router'
import { SET_ACCOUNT, SET_SESSION_TOKEN, SET_ERRORMSG } from '../mutation_types'
import {
    LOGIN_CHECK_EMAIL,
    SIGNUP,
    CLEAR_ACCOUNT_ISLOADING,
    LOGIN_CHECK_PASSWORD,
    VERIFY_TOKEN,
    SIGNUP_CHECK_EMAIL
} from '../action_types'
import { StateObject } from '@/dtos/state'
import Auth from '@/services/auth'
import { EmailVerification, LoginCredentals, RegistrationCredentals, VerificationCredentials } from '@/dtos/auth.dto'
import { Module } from 'vuex'

const account: Module<any, {}> = {
    state: {
        account: {
            data: null,
            status: null,
            isLoading: false
        },
        sessionToken: null
    },
    getters: {
        account: (state: { account: any }) => state.account,
        sessionToken: (state: { sessionToken: any }) => state.sessionToken
    },
    mutations: {
        [SET_ACCOUNT]: (state: { account: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.account.data = payload.data : null, state.account.status = payload.status, state.account.isLoading = payload.isLoading },
        [SET_SESSION_TOKEN]: (state: { sessionToken: any }, payload: any) => { state.sessionToken = payload },
    },
    actions: {
        [LOGIN_CHECK_EMAIL]: async({ commit }: any, payload: string) => {
            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.checkEmail(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log(result.data)
                    commit(SET_ACCOUNT, { isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [LOGIN_CHECK_PASSWORD]: async({ commit }: any, payload: LoginCredentals) => {
            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.checkPassword(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log(result.data)
                    commit(SET_ACCOUNT, { isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [VERIFY_TOKEN]: async({ commit }: any, payload: VerificationCredentials) => {
            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.verifyToken(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log(result.data)
                    commit(SET_SESSION_TOKEN, result.data.jwtToken)
                    commit(SET_ACCOUNT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [SIGNUP_CHECK_EMAIL]: async({ commit }: any, payload: string) => {
            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.registrationCheckEmail(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log(result.data)
                    commit(SET_ACCOUNT, { isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [SIGNUP]: async({ commit }: any, payload: any) => {

            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.signup(payload as RegistrationCredentals)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_ACCOUNT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        // [VERIFY_EMAIL]: async({ commit }: any, payload: EmailVerification) => {

        //     console.log('payloadpayload', payload)
        //     commit(SET_ACCOUNT, { data: null, isLoading: true })

        //     let result: AxiosResponse = await Auth.verifyEmail(payload.token)

        //     if(result) {
        //         if (result.data.status) {
        //             commit(SET_ACCOUNT, { data: result.data.data, isLoading: false })
        //             return true
        //         }
        //         commit(SET_ACCOUNT, { isLoading: false })
        //         commit(SET_ERRORMSG, result.data.msg)
        //         return false
        //     } else {
        //         commit(SET_ACCOUNT, { isLoading: false })
        //         return false
        //     }
        // },
        [CLEAR_ACCOUNT_ISLOADING]: ({ commit }) => {
            commit(SET_ACCOUNT, { isLoading: false })
        }
    }
}

export default account