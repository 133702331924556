// account
export const SIGNUP = 'signup'
export const LOGIN_CHECK_EMAIL = 'login_check_email'
export const LOGIN_CHECK_PASSWORD = 'login_check_password'
export const VERIFY_TOKEN = 'VERIFY_TOKEN'

export const SIGNUP_CHECK_EMAIL = 'signup_check_email'
export const SIGNUP_CHECK_PASSWORD = 'signup_check_password'
export const SIGNUP_VERIFY_TOKEN = 'signup_verify_token'

export const CLEAR_ACCOUNT_ISLOADING = 'clearAccountIsLoading'

// error 
export const CLEAR_ERRORMSG = 'clearErrorMessage'
export const UPDATE_ERRORMSG = 'updateErrorMessage'