<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="css">
@import './assets/style/main.css';
@import './assets/style/tailwind.css';

</style>
