import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import filters from './filters/index'
import titleMixin from './mixin/titleMixin'

const app = createApp(App)
app.mixin(titleMixin)

app.use(store).use(router).mount("#app");

// app.config.globalProperties.$filters = filters

